#main {
  width: 50vw;
  margin: auto;
  margin-top: 10vh;
  width: 50vw;
  position: fixed;
  top: 5em;
  z-index: 35;
  color: black;
  left: calc(25vw - 2em);
  background: white;
  padding: 2em;
  background: radial-gradient(rgba(0, 0, 0, 0), #FAEBD7);
  font-family: 'Roboto', sans-serif;
  max-height: 50vh;
  overflow: scroll;
}

#canvasContainer {
  top: 0;
  position: fixed;
  height: 100vh;
  width: 100vw;
}

#video {
/*  display: none;*/
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur();
  filter: blur(20px);
  width: 110vw;
  left: -30px;
  top: -30px;
  position: fixed;
}

span {
    padding-right: 2rem;
}

.submit {
    font-family: 'Roboto', sans-serif;
    border: none;
    padding: .5rem 2rem;
    background-color: blanchedalmond;
    font-size: medium;
    font-style: italic;
}

.timer {
    color: #d60279;
    font-size: xx-large;
    padding: .5rem 2rem;
    font-style: strong;
    font-family: 'Courier', monospace;
    font-weight: bolder;
    text-align: center;
}

.token {
    display: block;
    margin: auto;
    width: 60%;
    box-shadow: 0px 0px 40px 40px #0ff;
    margin-top: 5rem;
    margin-bottom: 3rem;
}

.pink {
    z-index: 10;
    position: fixed;
    width: 200vh;
    animation: rotation 15s infinite linear;
}

.white {
    z-index: 8;
    position: fixed;
    width: 300vh;
    animation: rotation2 30s infinite linear;
    top: -50vh;
    opacity: 50%;
}

@keyframes rotation2 {
  from {
    transform: rotate(359deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@media only screen and (max-width: 1025px) {
    #video {
        width: unset;
        height: 110vh;
        left: -30vh;
    }

    .pink {
        width: 150vw;
    }

    .white {
        width: 250vw;
        left: -50vw;
        top: 50vw;
    }
}

